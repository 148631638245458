<template>
  <div class="test">
    <div class="img-item" v-for="item in imgList" :key="item.id">
      <div style="width:270px;margin-right: 10px;">
        <img style="width:270px;" draggable="false" @click="cj(item)" :src="item.src" v-if="item.src" class="icon">
        <fm-btn @click="dzcj(item)">定值自动裁剪{{item.id}}</fm-btn>
      </div>
      <div class="c-s" :style="item.d" v-if="item.success">
        <canvas :ref="'canvas' + item.id" :style="item.c1" class="c-s-1" width="300" height="300"></canvas>
        <canvas :ref="'canvasOver' + item.id"  :style="item.c2" class="c-s-2" width="300" height="300"></canvas>
      </div>
      <div v-else>自动裁剪失败{{item.id}}</div>
    </div>
    <fm-form-dialog
      form-title="自动裁剪"
      :open-dialog.sync="openDialog"
      :form-parms="formParms"
      label-alone
      :mask-closable="false"
      label-align="left"
      form-width="800px"
      @formSubmit="formSubmit"
      @handleClose="openDialog = false">
    </fm-form-dialog>
  </div>
</template>

<script>
import {
  fileRequest
} from '@/api'

import ImgCropping from '@/syslib/imgCropping'

let ids = '5496,5502,5504,5506,5508,5510,5512,5597,5599,5601,5603,5605,5607,5609,5611,5613,5555,5557,5559,5561,5563,5565,5567,5569,5571,5573,5575,5577,5579,5581,5583,5585,5587,5589,5591,5593,5514,5516,5518,5520,5522,5524,5526,5528,5530,5532,5534,5536,5538,5540,5542,5543,5545,5547,5549,5551'


// 裁剪成功且没有问题的 success true pass true
// 裁剪成功且只有点小问题的 success true pass true canTry true
// 裁剪成功但结果有问题的且可以试试 success true pass false canTry true
// 裁剪成功但结果有问题的且无法处理的 success true pass false canTry false
// 裁剪失败但可以试试 success false pass false canTry true
// 裁剪失败不用裁剪的 success false pass true canTry false
// 裁剪失败需要裁剪无法尝试的 success false pass false canTry false

let result = [{
  id: 5496,
  success: true,
  pass: true,
  type: 'exactSameColor',
  sameColorSub: 7,
  minJd: 1
},
{
  id: 5502,
  success: true,
  pass: true,
  type: 'ignoreBlackWhite',
  sameColorSub: 7,
  minJd: 1
},
{
  id: 5504,
  success: true,
  pass: true,
  type: 'ignoreBlackWhite',
  sameColorSub: 6,
  minJd: 1
},
{
  id: 5506,
  success: true,
  pass: true,
  type: 'exactSameColor',
  sameColorSub: 16,
  minJd: 1
},
{
  id: 5508,
  success: true,
  pass: true,
  type: 'ignoreBlackWhite',
  sameColorSub: 6,
  minJd: 1
},
{
  id: 5510,
  success: true,
  pass: true,
  type: 'minColor',
  minColor: 220,
  minJd: 1
},
{
  id: 5512,
  success: true,
  pass: true,
  type: 'minColor',
  minColor: 220,
  minJd: 1
},
{
  id: 5597,
  success: true,
  pass: true,
  type: 'exactSameColor',
  sameColorSub: 19,
  minJd: 1
},
{
  id: 5599,
  success: true,
  pass: true,
  type: 'ignoreBlackWhite',
  sameColorSub: 6,
  minJd: 1
},
{
  id: 5601,
  success: true,
  pass: true,
  type: 'exactSameColor',
  sameColorSub: 16,
  minJd: 1
},
{
  id: 5603,
  success: true,
  pass: true,
  type: 'minColor',
  minColor: 220,
  minJd: 1
},
{
  id: 5605,
  success: true,
  pass: true,
  type: 'ignoreBlackWhite',
  sameColorSub: 7,
  minJd: 1
},
{
  id: 5607,
  success: true,
  pass: true,
  type: 'minColor',
  minColor: 220,
  minJd: 1
},
{
  id: 5609,
  success: true,
  pass: true,
  type: 'minColor',
  minColor: 220,
  minJd: 1
},
{
  id: 5611,
  success: true,
  pass: true,
  type: 'minColor',
  minColor: 220,
  minJd: 1
},
{
  id: 5613,
  success: true,
  pass: true,
  type: 'exactSameColor',
  sameColorSub: 10,
  minJd: 1
},
{
  id: 5555,
  success: true,
  pass: true,
  type: 'exactSameColor',
  sameColorSub: 11,
  minJd: 1
},
{
  id: 5557,
  success: true,
  pass: true,
  type: 'minColor',
  minColor: 200,
  minJd: 1
},
{
  id: 5559,
  success: true,
  pass: true,
  type: 'exactSameColor',
  sameColorSub: 15,
  minJd: 1
},
{
  id: 5561,
  success: true,
  pass: true,
  type: 'ignoreBlackWhite',
  sameColorSub: 6,
  minJd: 1
},
{
  id: 5563,
  success: true,
  pass: true,
  type: 'ignoreBlackWhite',
  sameColorSub: 6,
  minJd: 1
},
{
  id: 5565,
  success: true,
  pass: true,
  type: 'ignoreBlackWhite',
  sameColorSub: 10,
  minJd: 1
},
{
  id: 5567,
  success: true,
  pass: false,
  remark: '需要裁剪,可以尝试自动裁剪',
  canTry: true,
  type: 'ignoreBlackWhite',
  sameColorSub: 6,
  minJd: 1
},
{
  id: 5569,
  success: true,
  pass: true,
  type: 'exactSameColor',
  sameColorSub: 17,
  minJd: 1
},
{
  id: 5571,
  success: true,
  pass: true,
  type: 'exactSameColor',
  sameColorSub: 17,
  minJd: 1
},
{
  id: 5573,
  success: true,
  pass: true,
  remark: 'mincolor 220 要好一点',
  type: 'exactSameColor',
  sameColorSub: 7,
  minJd: 1
},
{
  id: 5575,
  success: true,
  pass: true,
  type: 'ignoreBlackWhite',
  sameColorSub: 5,
  minJd: 1
},
{
  id: 5577,
  success: true,
  pass: true,
  type: 'minColor',
  minColor: 220,
  minJd: 1
},
{
  id: 5579,
  success: true,
  pass: true,
  type: 'ignoreBlackWhite',
  sameColorSub: 5,
  minJd: 1
},
{
  id: 5581,
  success: true,
  pass: true,
  type: 'minColor',
  minColor: 220,
  minJd: 1
},
{
  id: 5583,
  success: true,
  pass: true,
  type: 'minColor',
  minColor: 220,
  minJd: 1
},
{
  id: 5585,
  success: false,
  pass: true
},
{
  id: 5587,
  success: true,
  pass: true,
  type: 'exactSameColor',
  sameColorSub: 8,
  minJd: 1
},
{
  // // 裁剪成功但结果有问题的且无法处理的 success true pass false canTry false
  id: 5589,
  success: true,
  pass: true,
  remark: '偏得多,有角,没法继续优化了'
},
{
  id: 5591,
  success: true,
  pass: true,
  type: 'exactSameColor',
  sameColorSub: 8,
  minJd: 1
},
{
  id: 5593,
  success: true,
  pass: true,
  type: 'exactSameColor',
  sameColorSub: 8,
  minJd: 1
},
{
  id: 5514,
  success: true,
  pass: true,
  type: 'minColor',
  minColor: 220,
  minJd: 1
},
{
  id: 5516,
  success: true,
  pass: true,
  type: 'minColor',
  minColor: 200,
  minJd: 1
},
{
  id: 5518,
  success: true,
  pass: true,
  type: 'ignoreBlackWhite',
  sameColorSub: 7,
  minJd: 1
},
{
  id: 5520,
  success: true,
  pass: true,
  type: 'minColor',
  minColor: 210,
  minJd: 1
},
{
  id: 5522,
  success: true,
  pass: true,
  type: 'ignoreBlackWhite',
  sameColorSub: 9,
  minJd: 1
},
{
  id: 5524,
  success: true,
  pass: true,
  type: 'minColor',
  minColor: 200,
  minJd: 1
},
{
  id: 5526,
  success: true,
  pass: true,
  type: 'exactSameColor',
  sameColorSub: 14,
  minJd: 1
},
{
  id: 5528,
  success: false,
  remark: '需要裁剪,可以尝试自动裁剪',
  pass: false,
  canTry: true
},
{
  id: 5530,
  success: true,
  pass: true,
  type: 'ignoreBlackWhite',
  sameColorSub: 6,
  minJd: 1
},
{
  id: 5532,
  success: true,
  pass: true,
  type: 'ignoreBlackWhite',
  sameColorSub: 6,
  minJd: 1
},
{
  id: 5534,
  success: true,
  pass: false,
  canTry: true,
  remark: '需要裁剪,有问题',
  type: 'ignoreBlackWhite',
  sameColorSub: 4,
  minJd: 1
},
{
  id: 5536,
  success: true,
  pass: true,
  type: 'minColor',
  minColor: 200,
  minJd: 1
},
{
  id: 5538,
  success: true,
  pass: true,
  type: 'exactSameColor',
  sameColorSub: 7,
  minJd: 1
},
{
  id: 5540,
  success: false,
  pass: true
},
{
  id: 5542,
  success: false,
  remark: '需要裁剪,无法自动裁剪',
  pass: true
},
{
  id: 5543,
  success: true,
  pass: true,
  remark: 'minColor 230效果要好一点',
  type: 'exactSameColor',
  sameColorSub: 7,
  minJd: 1
},
{
  id: 5545,
  success: true,
  pass: true,
  type: 'ignoreBlackWhite',
  sameColorSub: 16,
  minJd: 1
},
{
  id: 5547,
  success: false,
  pass: true
},
{
  id: 5549,
  success: true,
  pass: true,
  type: 'exactSameColor',
  sameColorSub: 12,
  minJd: 1
},
{
  id: 5551,
  success: true,
  pass: true,
  type: 'exactSameColor',
  sameColorSub: 7,
  minJd: 1
}]

console.log(result)

// ids = result.filter(v => v.canTry).map(v => v.id).join(',')

ids = '5496,5502,5504,5506,5508,5510,5512,5597,5599'

export default {
  data () {
    return {
      openDialog: false,
      chooseData: null,
      imgList: ids.split(',').map(v => {
        return {
          id: Number(v),
          src: null,
          width: null,
          height: null,
          success: false,
          c1: {
            transform: 'scale(1)'
          },
          c2: {
            transform: 'scale(1)',
            left: '500px'
          },
          d: {
            'min-height': '0px'
          }
        }
      })
    }
  },
  methods: {
    cj (item, parm) {
      console.log(item, parm)
      item.success = false
      let imgCropping = new ImgCropping({
        imgData: item.imageData,
        width: item.width,
        height: item.height
      })
      imgCropping.compute(parm)
      let data = imgCropping.getCanvasParm()
      console.log(data)
      item.success = true
      let img = new Image()
      img.src = item.src
      let vm = this
      img.onload = function() {
        let dom = vm.$refs['canvas' + item.id][0]
        // let dom = document.createElement('canvas')
        let ctx = dom.getContext('2d')
        dom.width = data.sourceWidth
        dom.height = data.sourceHeight
        ctx.rotate(data.angle)
        ctx.translate(data.translatePoint[0], data.translatePoint[1])
        ctx.drawImage(img, data.imgStartPoint[0], data.imgStartPoint[1], data.sourceWidth, data.sourceHeight, 0,  0, data.sourceWidth, data.sourceHeight)
        let imgData = ctx.getImageData(data.croppingPoint[0], data.croppingPoint[1], data.width, data.height)
        let dom2 = vm.$refs['canvasOver' + item.id][0]
        let ctx2 = dom2.getContext('2d')
        dom2.width = data.width
        dom2.height = data.height
        ctx2.putImageData(imgData, 0, 0)
        let sf = 990 / (dom.width + dom2.width)
        item.c1.transform = 'scale(' + sf + ')'
        item.c2.transform = 'scale(' + sf + ')'
        item.c2.left = dom.width * sf + 10 + 'px'
        item.d['min-height'] = dom.height * sf + 'px'
      }
    },
    dzcj (item) {
      this.chooseData = item
      this.openDialog = true
    },
    formSubmit (data, a) {
      console.log(data, a)
      data.minColor = data.minColor ? Number(data.minColor) : null
      data.sameColorSub = data.sameColorSub ? Number(data.sameColorSub) : null
      this.openDialog = false
      a()
      this.cj(this.chooseData, data)
    },
    async loadData () {
      let i = 0
      while (i < this.imgList.length) {
        await this.loadFile(this.imgList[i])
        i += 1
      }
    },
    async loadFile (item) {
      let fileData = await fileRequest.download({
        id: item.id
      })
      const src = await new Promise(resolve => {
        let reader = new FileReader()
        reader.readAsDataURL(fileData)
        reader.onload = () => resolve(reader.result)
      })
      item.src = src
      let img = new Image()
      img.src = item.src
      img.onload = function() {
        let c = document.createElement('canvas')
        let ctx = c.getContext('2d')
        c.width = img.width
        c.height = img.height
        ctx.drawImage(img,0,0)
        item.width = c.width
        item.height = c.height
        item.imageData = ctx.getImageData(0, 0, c.width, c.height).data
      }
    }
  },
  computed: {
    formParms () {
      return [{
        type: 'select',
        label: '方式',
        key: 'type',
        selectDatas: [{key: 'minColor', label: '最小颜色值'}, {key: 'exactSameColor', label: '准确相似颜色'}, {key: 'sameColor', label: '相似颜色'}, {key: 'ignoreBlackWhite', label: '忽略黑白'}]
      },
      {
        type: 'input',
        label: '最小颜色值',
        key: 'minColor'
      },
      {
        type: 'input',
        label: '相似颜色差',
        key: 'sameColorSub'
      }]
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>

<style scoped lang="less">
.test {
  background: #000;
  display:block;
}
.img-item {
  display: flex;
  align-items: flex-start;
  padding: 10px 0;
}
.c-s {
  position: relative;
  width: 100px;
}
.c-s-1 {
  position: absolute;
  background: red;
  top: 0;
  left: 0;
  transform-origin: 0 0;
}
.c-s-2 {
  position: absolute;
  background: red;
  top: 0;
  transform-origin: 0 0;
}
</style>